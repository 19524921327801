<template>
  <div class="footer">
    <div  class="stuff">
      <span class="element">Iordanis Paschalidis / junk.Dog</span>
      <a  href="https://twitter.com/iordanispriming" target="_blank" class="fa fa-twitter element" ></a>
      <a  href="https://www.instagram.com/junk.d0g" target="_blank" class="fa fa-instagram element" ></a>
      <a  href="https://github.com/junkd0g" target="_blank" class="fa fa-github element" ></a>
      <a  href="https://www.linkedin.com/in/iordanis-paschalidis" target="_blank" class="fa fa-linkedin element" ></a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  components: {
  }
}
</script>

<style>
.footer {
   position: fixed;
   left: 0;
   bottom: 0;
   height:50px;
   width: 100%;
   background-color: #1D1D1D;
   color: white;
   text-align: center;
}
.stuff {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.fa {
  border-radius: 30px;
  padding: 5px;
  font-size: 20px;
  width: 20px;
  text-align: center;
  text-decoration: none;
}
/* Linkedin */
.fa-linkedin {
  background: #0077B5;
  color: white;
}
/* Twitter */
.fa-twitter {
  background: #55ACEE;
  color: white;
}
/* Github */
.fa-github {
  background: #333;
  color: white;
}
/* Instagram */
.fa-instagram {
  background: #bc2a8d;
  color: white;
}
/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.4;
}
.element {
  margin-right: 10px;
}

</style>
