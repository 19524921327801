<template>
  <div class="header">
    <div></div>
      <div class="burger "><Slide right >
        <a id="Profile" href="/">
          <span>Home</span>
        </a>
        <a id="About us" href="/about">
          <span>About</span>
        </a>
         <a>
          <span>Dependecies</span>
        </a>
         <a>
          <span>Projects</span>
        </a>
      </Slide>
    </div>
  </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'

export default {
  name: 'Header',
  components: {
    Slide
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.bm-burger-button {
      position: fixed;
      width: 22px;
      height: 22px;
      left: 20px;
      top: 20px;
      cursor: pointer;
    }
    .bm-burger-bars {
      background-color: #373a47;
    }
    .line-style {
      position: absolute;
      height: 10%;
      left: 0;
      right: 0;
    }
    .cross-style {
      position: absolute;
      top: 20px;
      left: 20px;
      cursor: pointer;
    }
    .bm-cross {
      background: #bdc3c7;
    }
    .bm-cross-button {
      height: 24px;
      width: 24px;
    }
    .bm-menu {
      height: 100%; /* 100% Full-height */
      width: 0; /* 0 width - change this with JavaScript */
      position: fixed; /* Stay in place */
      z-index: 1000; /* Stay on top */
      top: 0;
      left: 0;
      background-color: #1D1D1D; /* Black*/
      overflow-x: hidden; /* Disable horizontal scroll */
      padding-top: 60px; /* Place content 60px from the top */
      transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
    }
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
    .bm-item-list {
      color: white;
      margin-left: 10%;
      font-family: 'Inter', sans-serif;

      font-size: 18px;
    }
    .bm-item-list > * {
      display: flex;
      text-decoration: none;
      padding: 0.7em;
    }
    .bm-item-list > * > span {
      margin-left: 10px;
      font-weight: 500;
      color: white;
    }
</style>
